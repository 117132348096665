$primary: #414141;
$secondary: #252525;
$bg: #111111;
$text: #eeeeee;
$red: #ff0000;

body, html {
	color: $text;
	//background-color: $bg;
    background: $bg url('/assets/background.png');
}

a {
    color: white;
    text-decoration: none;
    font-weight: bold;

    &:hover {
        color: $red;
        text-decoration: none;
        font-weight: bolder;
    }
}



h1, h2, h3, h4, h5, h6 {
	font-family: 'Audiowide', cursive;
	color: $red;
}

section h2, section h3 {
    margin: 30px 0;
}

.header-name h1 {
    font-size: 3rem;
}

section h2 {
    font-size: 3rem;
}

.navbar-brand {
	font-family: 'Audiowide', cursive;
    font-weight: normal;

    &:hover {
        color: $red!important;
        font-weight: bold;
    }
}

.navbar {
	background-color: rgba($primary, 0.75);
}
.nav-link {
    font-weight: normal;
    &:hover {
        font-weight: normal;
    }
}

header {
	background-color: $secondary;
}

#header-carousel .carousel-inner .carousel-item {
  height: 100vh;
  min-height: 350px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

header .container {
	@media (min-width: 768px) {
		padding-top: 150px;
		padding-bottom: 100px;
	}

	text-align:  center;
	padding-top: 100px;
	padding-bottom: 50px;
}

.header-name {
	position: absolute;
	z-index: 100;
	text-align: center;
	top: 40%;
	left: 0;
	width: 100%;
	background-color: rgba($primary, 0.9);
	
	background: linear-gradient(90deg, rgba($primary, 0) 0%, rgba($primary,0.7) 30%, rgba($primary,0.9) 50%, rgba($primary,0.7) 70%, rgba($primary, 0) 100%);
	padding: 25px 0;
}

.skills {
	text-align: center;
	color: black;

	ul {
		margin: 15px 0;
	}

	h3 {
		color: white;
	}

	.high .list-group-item {
		background-color: red;
	}

	.medium .list-group-item {
		background-color: orange;
	}

	.low .list-group-item {
		background-color: green;
	}
}

section .container {
	@media (min-width: 768px) {
		padding-top: 100px;
		padding-bottom: 100px;
	}

	text-align:  center;
	padding-top: 50px;
	padding-bottom: 50px;

	//color: black;
}


#about {
	background-color: $primary;
	color: white;
}

.portfolio-item {
	margin: 15px 0;
	
	
}

.portfolio-item:hover {
    filter: drop-shadow(0px 0px 10px $primary) contrast(80%);
}

footer {
	background-color: $primary;
	padding: 25px 0;
	text-align: center;
}

.social {
	margin-bottom: 0;

	color: white!important;

	li a {
		color: white!important;

		:hover {
			color: $red!important;
		}
	}
}


//Project Page
.project-header {
	background-repeat:repeat;
	background-position: center center!important;

	@media (min-width: 768px) {
		padding-top: 250px;
		padding-bottom: 200px;
	}

	text-align:  center;
	padding-top: 200px;
	padding-bottom: 150px;

	margin-top: 56px;

	div {
		//height: 50%;
		background-color: rgba($primary, 0.5);
		background: linear-gradient(90deg, rgba($primary, 0) 0%, rgba($primary,0.7) 30%, rgba($primary,0.9) 50%, rgba($primary,0.7) 70%, rgba($primary, 0) 100%);
		padding: 25px 0;
	}
}


.project-name {
	background-color: #bb2222;
	padding: 5px;
	
	a {
		color: $text;
		font-size: 14pt;
		font-weight: 500;
	}

}

.project-desc {
    h1, h2, h3, h4, h5, h6 {
        text-align: center;
    }
}

.tags {
	background-color: #191970;
	padding: 5px;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	a {
		color: $text;
		font-size: 14pt;
		font-weight: 500;
	}

}

.remove-list-style
{
    list-style-type: none!important;
    padding-inline-start: 0;
}

.carousel-control-prev, .carousel-control-next
{
    color: $red!important;
    font-size: 30px;
}

.carousel-indicators li {
    background-color: $red;
}

.ghost {
    max-width: 30%;
    height: auto;
}